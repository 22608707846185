import React from "react";
import Link from "gatsby-link";
import styles from "./Staff.module.css";
import SectionTitle from "../SectionTitle/SectionTitle";

const Staff = props => {
  return (
    <section className={styles.wrapper}>
      <SectionTitle heading="Meet Our Staff" />
      <ul className={styles.list}>
        {props.staff.map(({ node }) => (
          <li key={node.id}>
            <Link to={node.childMarkdownRemark.fields.slug}>
              {node.childMarkdownRemark.frontmatter.title},{" "}
              {node.childMarkdownRemark.frontmatter.position}
            </Link>
          </li>
        ))}
      </ul>
    </section>
  );
};

export default Staff;
