import React from 'react'
import styles from './SectionTitle.module.css'
import Divider from '../Divider/Divider'

const SectionTitle = (props) => {

  return (
    <div className={styles.wrapper}>
      <h1>{props.heading}</h1>
      <Divider />
    </div>
  )
}

export default SectionTitle