import React from "react";
import Helmet from "react-helmet";
import { graphql } from "gatsby";
import { Spring, config } from "react-spring";
import Layout from "../components/layout";
import Header from "../components/Header/Header";
import PageContent from "../components/PageContent/PageContent";
import Contact from "../components/Contact/Contact";
import Footer from "../components/Footer/Footer";
import SideNav from "../components/SideNav/SideNav";
import styles from "./practiceAreaTemplate.module.css";

export default function PracticeAreaTemplate({ data }) {
  return (
    <Layout>
      <Helmet
        title={`${
          data.markdownRemark.frontmatter.title
        } - Abingdon, VA Lawyer - Jessee Read & Howard, P.C.`}
      />
      <div className="blog-post-container">
        <Header />
        <div className={styles.wrapper}>
          <Spring
            config={config.gentle}
            from={{ opacity: 0, transform: "translate3d(0,20px,0)" }}
            to={{ opacity: 1, transform: "translate3d(0,0px,0)" }}
          >
            {props => (
              <div style={props}>
                <PageContent
                  title={`Abingdon, VA ${
                    data.markdownRemark.frontmatter.title
                  } Lawyers`}
                  content={data.markdownRemark.html}
                  schedule
                />
              </div>
            )}
          </Spring>

          <SideNav items={data.practiceAreas.edges} />
        </div>
        <Contact />
        <Footer />
      </div>
    </Layout>
  );
}

export const pageQuery = graphql`
  query PracticeAreaByPath($slug: String!) {
    area: site {
      siteMetadata {
        phone
        tollfree
        address1
        address2
        address3
        fax
        hours
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      fields {
        slug
      }
      frontmatter {
        title
      }
    }
    practiceAreas: allMarkdownRemark(
      filter: { fields: { sourceName: { eq: "practice-areas" } } }
      sort: { fields: [frontmatter___title], order: ASC }
    ) {
      edges {
        node {
          id
          fields {
            slug
          }
          frontmatter {
            title
          }
        }
      }
    }
  }
`;
