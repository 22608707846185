import React from "react";
import Helmet from "react-helmet";
import { graphql } from "gatsby";
import { Spring, config } from "react-spring";
import Layout from "../components/layout";
import Header from "../components/Header/Header";
import Person from "../components/Person/Person";
import Contact from "../components/Contact/Contact";
import Footer from "../components/Footer/Footer";

export default function StaffTemplate({ data }) {
  return (
    <Layout>
      <Helmet
        title={`${
          data.markdownRemark.frontmatter.title
        } - Abingdon, VA Lawyer - Jessee Read & Howard, P.C.`}
      />
      <Header phone={data.site.siteMetadata.phone} />
      <Spring
        config={config.gentle}
        from={{ opacity: 0, transform: "translate3d(0,20px,0)" }}
        to={{ opacity: 1, transform: "translate3d(0,0px,0)" }}
      >
        {(props) => (
          <div style={props}>
            <Person
              name={data.markdownRemark.frontmatter.title}
              position={data.markdownRemark.frontmatter.position}
              photo={data.markdownRemark.frontmatter.photo}
              bio={data.markdownRemark.html}
            />
          </div>
        )}
      </Spring>
      <Contact />
      <Footer />
    </Layout>
  );
}

export const pageQuery = graphql`
  query StaffByPath($slug: String!) {
    site {
      siteMetadata {
        phone
        tollfree
        address1
        address2
        address3
        fax
        hours
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        position
        photo
      }
    }
  }
`;
