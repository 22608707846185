import React, { Component } from "react";
import { StaticQuery, graphql } from "gatsby";
import styles from "./Contact.module.css";
// import SectionTitle from "../SectionTitle/SectionTitle";
import Link from "gatsby-link";
import globalData from "../../content/global.yml";

class Contact extends Component {
  render() {
    return (
      <StaticQuery
        query={graphql`
          query ContactQuery {
            site {
              siteMetadata {
                title
                phone
                address1
                address2
                address3
                tollfree
                fax
                hours
                hours2
              }
            }
          }
        `}
        render={(data) => (
          <div className={styles.wrapper}>
            <div className={styles.overlay} />
            <div className={styles.inner}>
              <div className={styles.info}>
                <div>
                  {globalData.address1}
                  <br />
                  {globalData.address2}
                  <br />
                  {globalData.address3}
                </div>
                <div>
                  Tel: {globalData.phone}
                  <br />
                  Fax: {globalData.fax}
                  <br />
                  <br />
                  {globalData.hours}
                  <br />
                  {globalData.hours2}
                </div>
                <p className={styles.schedule}>
                  <Link to="/schedule-appointment/" className={styles.button}>
                    Schedule an Appointment
                  </Link>
                </p>
              </div>
            </div>
          </div>
        )}
      />
    );
  }
}

export default Contact;
