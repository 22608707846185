import React from 'react'
import styles from './Divider.module.css'

const Divider = (props) => {


  return (
    <div className={styles.divider}></div>
  )
}

export default Divider