import React from "react";
import styles from "./PageContent.module.css";
import Divider from "../Divider/Divider";
import ScheduleAppointment from "../../components/ScheduleAppointment/ScheduleAppointment";

const PageContent = props => {
  return (
    <div className={styles.mainWrapper}>
      <div className={styles.inner}>
        <h1>{props.title}</h1>
        <Divider />
        <div
          className="blog-post-content"
          dangerouslySetInnerHTML={{ __html: props.content }}
        />
        <ScheduleAppointment areas={props.areas} />
      </div>
    </div>
  );
};

export default PageContent;
