import React, { Component } from "react";
import { StaticQuery, graphql } from 'gatsby';
import { withStyles, MuiThemeProvider } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import TextField from "@material-ui/core/TextField";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import styles from "./ScheduleAppointment.module.css";
import { createMuiTheme } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";

const theme = createMuiTheme({
  palette: {
    primary: { main: "#C4942F" }
  }
});

class ScheduleAppointment extends Component {
  state = {
    preferred: "Phone",
    best_time: "Any Time",
    case_type: ""
  };

  handleChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  render() {
    return (
      
      <StaticQuery query={graphql`
      query SitePracticeAreas {
        allMarkdownRemark(filter: {fields: {sourceName: {eq: "practice-areas"}}}, sort: {fields: [frontmatter___title], order: ASC}) {
          edges {
            node {
              id
              fields {
                slug
              }
              frontmatter {
                title
              }
            }
          }
        }
      }
      `}
      render={data => (
        <MuiThemeProvider theme={theme}>
        <div className={styles.wrapper}>
          <h3>Schedule an Appointment</h3>
          <form
            method="post"
            name="schedule_appointment"
            data-netlify="true="
            data-netlify-honeypot="_gotcha"
          >
            <p>
              Kindly submit the form below and we'll give you a call to schedule
              an appointment.
            </p>

            <input
              type="hidden"
              name="form-name"
              value="schedule_appointment"
            />

            <TextField
              id="name"
              name="name"
              label="Your name"
              className={styles.textfield}
            />

            <TextField
              id="email"
              name="_replyto"
              label="Your email address"
              className={styles.textfield}
            />

            <TextField
              id="phone"
              name="phone"
              label="Your phone number"
              className={styles.textfield}
            />

            <FormControl className={styles.formcontrol}>
              <InputLabel htmlFor="preferred">
                Preferred contact method
              </InputLabel>
              <Select
                id="preferred"
                name="preferred"
                value={this.state.preferred}
                onChange={this.handleChange}
              >
                <MenuItem value="Phone">Phone</MenuItem>
                <MenuItem value="Email">Email</MenuItem>
              </Select>
            </FormControl>

            <FormControl className={styles.formcontrol}>
              <InputLabel htmlFor="best_time">
                Best time to call (if applicable)
              </InputLabel>
              <Select
                id="best_time"
                name="best_time"
                value={this.state.best_time}
                onChange={this.handleChange}
              >
                <MenuItem value="Any Time">Any Time</MenuItem>
                <MenuItem value="Morning">Morning</MenuItem>
                <MenuItem value="Afternoon">Afternoon</MenuItem>
                <MenuItem value="Evening">Evening</MenuItem>
              </Select>
            </FormControl>

            <FormControl className={styles.formcontrol}>
              <InputLabel htmlFor="case_type">
                Type of case
              </InputLabel>
              <Select
                id="case_type"
                name="case_type"
                value={this.state.case_type}
                onChange={this.handleChange}
              >
                {data.allMarkdownRemark.edges.map(area => (
                  <MenuItem key={area.node.frontmatter.title} value={area.node.frontmatter.title}>{area.node.frontmatter.title}</MenuItem>
                ))}
              </Select>
            </FormControl>
            <p className={styles.hidden}>
              <label>
                Don’t fill this out if you're human: <input name="_gotcha" />
              </label>
            </p>
            <Button variant="outlined" type="submit" className={styles.button}>
              Submit
            </Button>
          </form>
        </div>
        </MuiThemeProvider>
      )}
      />
      
    );
  }
}

export default withStyles(styles)(ScheduleAppointment);
