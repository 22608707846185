import React from "react";
import Helmet from "react-helmet";
import { graphql } from "gatsby";
import { Spring, config } from "react-spring";
import Layout from "../components/layout";
import Header from "../components/Header/Header";
import Person from "../components/Person/Person";
import Contact from "../components/Contact/Contact";
import Footer from "../components/Footer/Footer";

export default function AttorneyTemplate({ data }) {
  return (
    <Layout>
      <Helmet
        title={`${
          data.markdownRemark.frontmatter.title
        } - Abingdon, VA Lawyer - Jessee Read & Howard, P.C.`}
      />
      <Header phone={data.site.siteMetadata.phone} />
      <Spring
        config={config.gentle}
        from={{ opacity: 0, transform: "translate3d(0,20px,0)" }}
        to={{ opacity: 1, transform: "translate3d(0,0px,0)" }}
      >
        {(props) => (
          <div style={props}>
            <Person
              type="attorney"
              name={data.markdownRemark.frontmatter.title}
              position={data.markdownRemark.frontmatter.position}
              bio={data.markdownRemark.html}
              photo={data.markdownRemark.frontmatter.photo}
              award={data.markdownRemark.frontmatter.award}
              awards={data.markdownRemark.frontmatter.awards}
              isln={data.markdownRemark.frontmatter.isln}
              education={data.markdownRemark.frontmatter.education}
              admitted={data.markdownRemark.frontmatter.admitted}
              memberships={data.markdownRemark.frontmatter.memberships}
              employment={data.markdownRemark.frontmatter.employment}
            />
          </div>
        )}
      </Spring>
      <Contact />
      <Footer />
    </Layout>
  );
}

export const pageQuery = graphql`
  query AttorneyByPath($slug: String!) {
    site {
      siteMetadata {
        phone
        tollfree
        address1
        address2
        address3
        fax
        hours
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        position
        isln
        photo

        awards
        photo
        education {
          item
        }
        memberships {
          item
        }
        employment {
          item
        }
        admitted {
          item
        }
      }
    }
  }
`;
