import React from "react";
import styles from "./Person.module.css";
import Divider from "../../components/Divider/Divider";
import InfoList from "../../components/InfoList/InfoList";

const Person = props => {
  function Photo(props) {
    if (props.photo) {
      return (
        <div className={styles.photoWrapper}>
          <img src={props.photo} alt={props.name} />
        </div>
      );
    }
    return null;
  }

  function Admitted(props) {
    if (props.items) {
      return <InfoList heading="Admitted" items={props.items} />;
    }
    return null;
  }
  function Education(props) {
    if (props.items) {
      return <InfoList heading="Education" items={props.items} />;
    }
    return null;
  }
  function Memberships(props) {
    if (props.items) {
      return <InfoList heading="Memberships" items={props.items} />;
    }
    return null;
  }
  function Employment(props) {
    if (props.items) {
      return <InfoList heading="Employment" items={props.items} />;
    }
    return null;
  }

  function MoreInfo(props) {
    if (props.type === "attorney") {
      return (
        <div className={styles.moreWrapper}>
          <div className={styles.inner}>
            <Admitted items={props.admitted} />
            <Education items={props.education} />
            <Employment items={props.employment} />
            <Memberships items={props.memberships} />
          </div>
        </div>
      );
    }
    return null;
  }

  return (
    <div>
      <div className={styles.mainWrapper}>
        <div className={styles.inner}>
          <div className={styles.bioWrapper}>
            <h1 className={styles.name}>{props.name}</h1>
            <Divider />
            <p className={styles.position}>
              {props.position}
              {props.isln && `, ISLN: ${props.isln}`}
            </p>
            <div dangerouslySetInnerHTML={{ __html: props.bio }} />
          </div>
          <div>
            <Photo photo={props.photo} />
            <div className={styles.awards}>
              {props.awards &&
                props.awards.map(image => (
                  <div className={styles.award}>
                    <img src={image} className={styles.award} alt="Award" />
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>
      <MoreInfo
        type={props.type}
        admitted={props.admitted}
        education={props.education}
        memberships={props.memberships}
        employment={props.employment}
      />
    </div>
  );
};

export default Person;
