import React from "react";
import Link from "gatsby-link";
import styles from "./404.module.css";
import SectionTitle from "../components/SectionTitle/SectionTitle";
import Header from "../components/Header/Header";
import Layout from "../components/layout";
import Footer from "../components/Footer/Footer";
import Contact from "../components/Contact/Contact";

const NotFoundPage = () => (
  <Layout>
    <Header />
    <section className={styles.wrapper}>
      <SectionTitle heading="404: Page Not Found" />
      <p>
        You just hit a route that doesn&#39;t exist... the sadness. We're sorry
        for any confusion. Check out the <Link to="/">home page</Link> to get
        back on track.
      </p>
    </section>
    <Contact />
    <Footer />
  </Layout>
);
export default NotFoundPage;
