import React from "react";
import Link from "gatsby-link";
import styles from "./PracticeAreas.module.css";
import { Trail, animated } from "react-spring";

const PracticeAreas = props => {
  return (
    <section className={styles.wrapper}>
      <div className={styles.inner}>
        <h1 className={styles.sectionTitle}>Our Practice Areas</h1>
        <div className={styles.divider} />
        <ul className={styles.list}>
          <Trail
            native
            config={{ tension: 600, friction: 26, clamp: true }}
            items={props.areas}
            keys={item => item.node.id}
            from={{ opacity: 0, transform: "translate3d(0,-20px,0)" }}
            to={{ opacity: 1, transform: "translate3d(0,0px,0)" }}
          >
            {item => props => (
              <animated.li style={props}>
                <Link to={item.node.fields.slug}>
                  {item.node.frontmatter.title}
                </Link>
              </animated.li>
            )}
          </Trail>
        </ul>
      </div>
    </section>
  );
};

export default PracticeAreas;
