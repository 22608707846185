import React from 'react'
import styles from './InfoList.module.css'
import Divider from '../Divider/Divider'

const InfoList = (props) => {
  return (
    <div>
      <div className={styles.column}>
        <h2>{props.heading}</h2>
        <Divider />
        <ul className={styles.list}>
          {props.items.map(({ item }) => (
            <li key={item}>{item}</li>
          ))}
        </ul>
      </div>
    </div>
  )
}

export default InfoList