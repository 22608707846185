import React from "react";
import Helmet from "react-helmet";
import "../normalize.css";
import "./layout.css";
import "typeface-pt-serif";

const Layout = ({ children }) => (
  <div>
    <Helmet
      title="Abingdon, VA Lawyers - Jessee Read & Howard, P.C."
      meta={[
        {
          name: "description",
          content:
            "With more than 60 years of combined experience, we are Abingdon, VA attorneys dedicated to quality legal representation in many practice areas."
        }
      ]}
    />
    {children}
  </div>
);

export default Layout;
