import React from "react";
import Link from "gatsby-link";
import styles from "./404.module.css";
import SectionTitle from "../components/SectionTitle/SectionTitle";
import Header from "../components/Header/Header";
import Layout from "../components/layout";
import Footer from "../components/Footer/Footer";
import Contact from "../components/Contact/Contact";

const Success = () => (
  <Layout>
    <Header />
    <section className={styles.wrapper}>
      <SectionTitle heading="Thank You" />
      <p>
        We have received your submission and will get back with you shortly.
        Feel free to return to the <Link to="/">home page</Link> to explore
        more.
      </p>
    </section>
    <Contact />
    <Footer />
  </Layout>
);
export default Success;
