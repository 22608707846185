import React from "react";
import styles from "./Intro.module.css";
import logo from "../../assets/logo-light2.png";
import Link from "gatsby-link";

const Intro = props => {
  return (
    <section className={styles.wrapper}>
      <div className={styles.overlay} />
      <div className={styles.logoWrapper}>
        <img
          className={styles.logo}
          src={logo}
          alt="Jessee, Read & Howard, P.C."
        />
      </div>
      <div className={styles.textWrapper}>
        <p className={styles.text}>
          With more than 40 years of combined experience, Abingdon, VA attorneys
          John E. Jessee and Heather R. Howard are known by peers and clients
          for their dedication to quality legal representation in many practice
          areas. Call us at{" "}
          <span className={styles.action}>
            <a href={`tel:${props.phone}`}>{props.phone}</a>
          </span>{" "}
          or{" "}
          <Link to="/schedule-appointment/" className={styles.action}>
            schedule an appointment
          </Link>{" "}
          today.
        </p>
      </div>
    </section>
  );
};

export default Intro;
