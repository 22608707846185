import React from "react";
import styles from "./ImageStrip.module.css";

export default props => (
  <div
    className={styles.wrapper}
    style={{ backgroundImage: `url(${props.image})` }}
  >
    <div className={styles.text}>{props.text}</div>
  </div>
);
