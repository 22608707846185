import React from "react";
import Link from "gatsby-link";
import styles from "./Attorneys.module.css";
import SectionTitle from "../SectionTitle/SectionTitle";

const Attorneys = props => {
  return (
    <section className={styles.wrapper}>
      <SectionTitle
        heading="Attorneys You Know and Trust"
        textColor="accent"
        accentColor="accent"
      />
      <ul className={styles.list}>
        {props.attorneys.map(({ node }) => (
          <li key={node.id}>
            <Link to={node.fields.slug}>
              {node.frontmatter.title}, {node.frontmatter.position}
            </Link>
          </li>
        ))}
      </ul>
    </section>
  );
};

export default Attorneys;
