import React, { Component } from "react";
import { StaticQuery, graphql } from "gatsby";
import Link from "gatsby-link";
import styles from "./Header.module.css";
import logo from "../../assets/logo2.svg";
import { TiArrowLeft } from "react-icons/ti";
import { TiPhone } from "react-icons/ti";
import globalData from "../../content/global.yml";

class Header extends Component {
  render() {
    function goBack() {
      window.history.back();
    }
    return (
      <StaticQuery
        query={graphql`
          query HeaderQuery {
            site {
              siteMetadata {
                title
                phone
              }
            }
          }
        `}
        render={(data) => (
          <header className={styles.wrapper}>
            <div className={styles.nav}>
              <Link onClick={goBack} className={styles.backButton} to="/">
                <TiArrowLeft className={styles.icon} />
              </Link>
            </div>
            <div className={styles.logo}>
              <Link to="/">
                <img
                  className={styles.logoImage}
                  src={logo}
                  alt="Jessee, Read & Howard, P.C."
                />
              </Link>
            </div>
            <div className={styles.phone}>
              <div className={styles.phoneText}>
                <a href={`tel:${globalData.phone}`}>
                  <TiPhone className={styles.phoneIcon} />
                  <div className={styles.number}>{globalData.phone}</div>
                </a>
              </div>
            </div>
          </header>
        )}
      />
    );
  }
}

export default Header;
