import React from "react";
import Helmet from "react-helmet";
import styles from "./schedule-appointment.module.css";
import Header from "../components/Header/Header";
import Layout from "../components/layout";
import Footer from "../components/Footer/Footer";
import Contact from "../components/Contact/Contact";
import ScheduleAppointment from "../components/ScheduleAppointment/ScheduleAppointment";

const NotFoundPage = () => (
  <Layout>
    <Helmet
      title={`Schedule an Appointment - Abingdon, VA Lawyer - Jessee Read & Howard, P.C.`}
    />
    <Header />
    <section className={styles.wrapper}>
      <ScheduleAppointment />
    </section>
    <Contact />
    <Footer />
  </Layout>
);
export default NotFoundPage;
