import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import Intro from "../components/Intro/Intro";
import Attorneys from "../components/Attorneys/Attorneys";
import Staff from "../components/Staff/Staff";
import PracticeAreas from "../components/PracticeAreas/PracticeAreas";
import Contact from "../components/Contact/Contact";
import Footer from "../components/Footer/Footer";
import ImageStrip from "../components/ImageStrip/ImageStrip";
import styles from "./index.module.css";

const IndexPage = ({ data }) => (
  <Layout>
    <Intro phone={data.site.siteMetadata.phone} />
    <PracticeAreas areas={data.practiceAreas.edges} />
    <ImageStrip image="/images/justice.jpg" text="Experience matters." />
    <div className={styles.people}>
      <div className={styles.inner}>
        <Attorneys attorneys={data.attorneys.edges} />
        <Staff staff={data.staff.edges} />
      </div>
    </div>
    <Contact />
    <Footer />
  </Layout>
);

export default IndexPage;

export const query = graphql`
  query PageQuery {
    site {
      siteMetadata {
        phone
      }
    }
    attorneys: allMarkdownRemark(
      filter: { fields: { sourceName: { eq: "attorneys" } } }
      sort: { fields: [frontmatter___weight], order: ASC }
    ) {
      edges {
        node {
          id
          fields {
            slug
          }
          frontmatter {
            title
            position
            weight
            photo
            isln
            education {
              item
            }
            memberships {
              item
            }
            admitted {
              item
            }
            employment {
              item
            }
          }
          html
        }
      }
    }
    practiceAreas: allMarkdownRemark(
      filter: { fields: { sourceName: { eq: "practice-areas" } } }
      sort: { fields: [frontmatter___title], order: ASC }
    ) {
      edges {
        node {
          id
          fields {
            slug
          }
          frontmatter {
            title
          }
        }
      }
    }
    staff: allFile(filter: { sourceInstanceName: { eq: "staff" } }) {
      edges {
        node {
          id
          childMarkdownRemark {
            fields {
              slug
            }
            frontmatter {
              title
              position
              photo
            }
            html
          }
        }
      }
    }
  }
`;
