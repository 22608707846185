import React, { Component } from "react";
import { Link } from "gatsby";
import styles from "./SideNav.module.css";

class SideNav extends Component {
  render() {
    const isActive = ({ isPartiallyCurrent }) => {
      return isPartiallyCurrent ? { className: "active" } : null;
    };

    return (
      <div className={styles.wrapper}>
        <div>
          <h3>Practice Areas</h3>
          <ul className={styles.list}>
            {this.props.items.map(({ node }) => (
              <li key={node.frontmatter.title}>
                <Link to={`${node.fields.slug}`} getProps={isActive}>
                  {node.frontmatter.title}
                </Link>
              </li>
            ))}
          </ul>
        </div>
      </div>
    );
  }
}

export default SideNav;
